.App {
  overflow-x: hidden;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.nav-link {
  color: #006BA6 !important;
}

h1,
h2,
h3,
h4,
span,
p,
small,
li,
select,
input,
a {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}

p, small, span, li {
  line-height: 1.45;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 15px;
}

#silver {
  color: silver;
}

body {
  /*background-color:  */
  /*background-image: url(../src/assets/images/background-image.png);*/
  /*background-repeat: repeat;*/
  /*background-attachment: fixed;*/
}

li, p {
  font-size: 14px;
  margin-bottom: 6px;
}

ol, ul {
  padding-left: 16px;
}
a.nav-link {
  font-size: 16px;
}

@media only screen and (max-width: 460px) {
  a.nav-link {
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

li {
}
